/* global Office, OfficeExtension */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import { createRoot } from 'react-dom/client'

// import { AppContainer } from 'react-hot-loader'

import './debug'

const CONFIG_DEBUG_OFFICE_INIT = false

let isOfficeInitialized = false

const title = 'lease Task Pane Add-in'

/* =========================================== */
//          Timing functions
window.__times = []

const time = (name) => {
    const date = new Date()
    const ts = +date

    const diff = ts - (window.__times.slice(-1)[0] || { ts }).ts

    window.__times.push({
        name,
        date,
        ts,
        diff,
    })

    console.log(`TIME: ${name}`, date, ts)
}

window.time = time
/* =========================================== */

const render = async () => {
    // Lazy load app so Word is available and initialised by time app loads.
    const App = (await import('./App.js')).default
    const container = document.getElementById('container')
    const root = createRoot(container)

    root.render(<App title={title} isOfficeInitialized={isOfficeInitialized} />)
}

/* Render application after Office initializes */
if (CONFIG_DEBUG_OFFICE_INIT) {
    console.log('Office', Office, Office.context)
}

Office.initialize = () => {
    if (CONFIG_DEBUG_OFFICE_INIT) {
        console.log('Office.init')
    }
    isOfficeInitialized = true
    OfficeExtension.config.extendedErrorLogging = true
    render()
}

const inOffice = Object.keys(Office.context || {}) !== 0

if (!inOffice) {
    if (CONFIG_DEBUG_OFFICE_INIT) {
        console.warn('Not in office!')
    }

    document.body.innerHTML = `
        <div style="text-align:center; font-family: arial; margin: 150px;">
            <h1>Leasle Office Addin</h1>
        </div>
    `
    // render()
}
// render()
/* Initial render showing a progress bar */
// render(App)

// if (module.hot) {
//     module.hot.accept('./App', () => {
//         // eslint-disable-next-line global-require
//         const NextApp = require('./App').default
//         render(NextApp)
//     })
// }
