const testData = {
    a: {
        BD1000: 'Test Building Name',
        LL1000: 'LL Name',
        LL1050: 'LMTD',
        LL1060: 'ENGW',
        LL1100: '2213211',
        LL1200: 'LL Add 1',
        LL1400: 'LL Town',
        LL1500: 'LL Post',
        LL1300: 'LL Add 2',
        LL1600: 'GB',
        LL1700: 'LL C Name',
        LL1800: 'LL C DDI',
        LL1900: 'LL C Mobile',
        LL2000: 'LL C Email',

        TN1700: 'T C Name',
        TN1800: 'T C DDI',
        TN1900: 'T C Mobile',
        TN2000: 'T C Email',

        GR1000: 'G Name',
        GR1200: 'G Address 1',
        GR1300: 'G Address 2',
        GR1400: 'G Town',
        GR1500: 'G Post',
        GR100T: 'true',
        GR1600: 'GB',
        GR1700: 'G C Name',
        GR1800: 'G C DDI',
        GR1900: 'G C Mobile',
        GR2000: 'G C Email',

        LA1000: 'L A Name',
        LA1100: 'L A Address 1',
        LA1200: 'L A Address 2',
        LA1300: 'L A Town',
        LA1400: 'L A Post',
        LA1500: 'GB',
        LA1600: 'L A C Name',
        LA1700: 'L A C DDI',
        LA1800: 'L A C Mobile',
        LA1900: 'L A C Email',

        PR1000: 'P Name',
        PR1700: '1',
        TS1000: '2',
        TM1000: '2',
        TM1100: '6',
        TM1110: '6',
        TM1150: 'DATE',
        TM1200: '2',

        MR1000: '1',
        MR1010: 'Ten Thousand',
        MR1125: '2',
        MR120T: 'true',
        MR1200: '5',
        MR1225: 'WEEK',

        TN1000: 'T Name',
        TN1200: 'T Address 1',
        TN1300: 'T Address 2',
        TN1400: 'T Town',
        TN1500: 'T Post',
        TN1600: 'GB',
    },
    GR: {
        GR1000: 'G Name',
        GR1200: 'G Address 1',
        GR1300: 'G Address 2',
        GR1400: 'G Town',
        GR1500: 'G Post',
        GR100T: 'true',
        GR1600: 'GB',
        GR1700: 'G C Name',
        GR1800: 'G C DDI',
        GR1900: 'G C Mobile',
        GR2000: 'G C Email',
    },
    RR: {
        RR100T: 'true',
        RR1100: '2',
        RR1101: 'STRT',
        RR1102: '1YER',
        RR1103: 'ANNL',
        RR1104: 'TMSD',
        RR1600: 'UPON',
    },
}

export default testData
