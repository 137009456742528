/* global sm Word */
window.DEBUG = {}

import testData from './debug-data.js'

const wordRun = () =>
    new Promise((resolve) => {
        Word.run((context) => {
            resolve(context)
            return Promise.resolve()
        })
    })

const DEBUG = {}

DEBUG.init_data = async (set = 'a') => {
    if (!testData[set]) {
        console.warn(`Can't find test data set "${set}"`)
        return
    }
    console.time('DEBUG init_data')
    const data = testData[set]
    for (const name of Object.keys(data)) {
        await sm.setFieldValue(name, [data[name]], true)
    }
    console.timeEnd('DEBUG init_data')
}

DEBUG.get_data = () => {
    const data = sm._state.fieldDataValues
    const output = {}
    Object.keys(data).map((key) => {
        output[key] = data[key][0]
    })
    console.log(JSON.stringify(output, null, 4))
}

DEBUG.insert_control = async (tag, editable = true) => {
    const ctx = await wordRun()

    const selection = ctx.document.getSelection()
    const cc = selection.insertContentControl()

    cc.tag = tag
    cc.title = tag
    cc.placeholderText = ''
    cc.appearance = Word.ContentControlAppearance.tags
    cc.cannotEdit = !editable
    cc.color = '#EC008C'

    await ctx.sync()
}

DEBUG.to_dot = () => {
    const deps = sm._state.depGraph

    const content = Object.keys(deps)
        .map((tag) => {
            const dep = deps[tag]
            if (dep.size == 0) return ''
            return `    "${tag}" -> {${Array.from(dep)
                .map((a) => `"${a}"`)
                .join(' ')}}`
        })
        .filter((a) => !!a)
        .join('\n')

    const allNodes = Object.keys(deps)
        .map((tag) => {
            const dep = deps[tag]
            if (dep.size == 0) return ''
            if (!tag.includes('.')) return
            return `"${tag}"`
        })
        .filter((a) => !!a)
        .join(' ')

    const output = `
    digraph G {
        "TOP" -> {${allNodes}}
        ${content}
    }
    `

    console.log(output)
}

window.DEBUG = DEBUG
